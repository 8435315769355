.box-shadow {
    -webkit-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.34);
    -moz-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.34);
    box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.34);

    border-radius: 6px;
}

@media screen and (min-width:961px) {
    .customer-form {
        width: 500px
    }
}

@media screen and (max-width:960px){
    .customer-form {
        width: 400px
    }
}

@media screen and (max-width:479px){
    .customer-form {
        width: 300px
    }
}
